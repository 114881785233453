/* Set the width of the side navigation to 250px and the left margin of the page content to 250px and add a black background color to body */
function openNav() {
    document.getElementById("page-wrapper").classList.add('mobile-menu-active');
}

/* Set the width of the side navigation to 0 and the left margin of the page content to 0, and the background color of body to white */
function closeNav() {
    document.getElementById("page-wrapper").classList.remove('mobile-menu-active');
}

function goBack() {
    $(".sidenav-panel").removeClass("sidenav-panel_open");
}

function toggleSearchBox() {
    $(".sidenav-searchbox").toggleClass("sidenav-searchbox_open");
}

$(document).ready(function () {
    var $mainNav =  $( "#nav-main" ).clone().html();
    var $menu = '<div class="sidenav" id="mySidenav">\n' +
        '          <div class="sidenav-header">Menu\n' +
        '            <div class="sidenav-control"><a class="closebtn" href="javascript:void(0)" onclick="closeNav()"><span>×</span></a></div>\n' +
        '          </div>\n' +
        '          <div class="sidenav-panels">\n' +
        '            <div class="sidenav-panel" id="panel-menu">\n' +
        '               <ul>' + $mainNav + '</ul>\n' +
        '            </div>\n' +
        '          </div>\n' +
        '        </div>';

    $($menu).appendTo( "#page-wrapper" );

    $("#panel-menu ul li.has-subitems").each(function(index,value){

        var subItems = $(value).html();

        var panel = '<div id="panel-'+ index +'" class="sidenav-panel"><a href="javascript:void(0)" onclick="goBack()" class="backbtn"><span>&lsaquo;</span> Terug</a><ul>'+ subItems +'</ul></div>';
        $(".sidenav-panels").append(panel);

        $(this).find('a').click(function(e){
            var $parentpanel = $("#panel-menu");
            var $panel = $("#panel-"+ index);
            $parentpanel.addClass("sidenav-panel_open");
            $panel.addClass("sidenav-panel_open");
        });
    });
});