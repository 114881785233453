$(document).ready(function () {
    matchHeight();
    handleCarousel();
    gallery();
    handleTable();
    handleQuicklink();
    randomImageSidebar();
    handleSelect();

    $('body').addClass("loading");

    $(window).resize(function() {
        handleQuicklink();
    });
});

$(window).ready(function() {
    handleFooter();
    $(document).trigger('resize');
});

function handleQuicklink() {
    var element = $('.quicklink');
    var tileWidth = element.outerWidth( true );
    var tileHeight = tileWidth;

    element.height(tileHeight)
}

function matchHeight() {
    $('article .block-title').matchHeight();
}

function handleCarousel() {
    $('.owl-carousel').owlCarousel({
        items:1
    })
}

function goToUrl() {
    //get the dates
	var startDate = $("#downloadAgendaModal #startDate").val();
	var endDate = $("#downloadAgendaModal #endDate").val();

	//check on empty dates
    if(startDate === '') {
		startDate = moment().format('DD/MM/YYYY');
    }
	if(endDate === '') {
		endDate = moment().format('DD/MM/YYYY');
	}

	//convert to array
	startDate = startDate.split('/');
	endDate = endDate.split('/');

	//build from moment.js
	var startDate = moment(startDate[2]+"-"+startDate[1]+"-"+startDate[0]);
	var endDate = moment(endDate[2]+"-"+endDate[1]+"-"+endDate[0]);

	//calculate diff
    var diff = endDate.diff(startDate, 'days');

    //open the link
	var url = "//" + location.host + "/locatie-data/export?start=" + startDate.format('DD-MM-YYYY') + "&days=" + Math.abs(diff);
	window.location = url;

	// a.diff(b, 'days')
	//
	// var newStartDate = new Date(startDate.split("/")[1]+"/"+startDate.split("/")[0]+"/"+startDate.split("/")[2]).getTime();
	// var endDate = window.document.getElementById("endDate").value;
	// var newEndDate = new Date(endDate.split("/")[1]+"/"+endDate.split("/")[0]+"/"+endDate.split("/")[2]).getTime()
	// var days = Math.round((newEndDate - newStartDate) / (1000 * 60 * 60 * 24));
	// window.location = "//" + location.host + "/locatie-data/export?start=" + startDate + "&days=" + days;

    // var startDate = window.document.getElementById("startDate").value;
    // var newStartDate = new Date(startDate.split("/")[1]+"/"+startDate.split("/")[0]+"/"+startDate.split("/")[2]).getTime();
    // var endDate = window.document.getElementById("endDate").value;
    // var newEndDate = new Date(endDate.split("/")[1]+"/"+endDate.split("/")[0]+"/"+endDate.split("/")[2]).getTime()
    // var days = Math.round((newEndDate - newStartDate) / (1000 * 60 * 60 * 24));
    // window.location = "http://zaalverhuurapeldoorn.portalserver.nl/locatie-data/export?start="+startDate+"&days="+days+"";
}

function gallery() {

    $(".gallery").each(function(index,item) {

        $(this).magnificPopup({
            delegate: 'a',
            type: 'image',
            tLoading: 'Loading image #%curr%...',
            mainClass: 'mfp-img-mobile mfp-fade',
            gallery: {
                enabled: true,
                navigateByImgClick: true,
                preload: [0, 1], // Will preload 0 - before current, and 1 after the current image
                tCounter: '%curr% van %total%' // Markup for "1 of 7" counter
            },
            image: {
                tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
                titleSrc: function (item) {
                    return item.el.attr('title');
                }
            },
            removalDelay: 300
        });

        var galleryLength = $(item).find("a").length;

        if (galleryLength == 1) {
            $(item).addClass('one-image');
        } else if (galleryLength == 2) {
            $(item).addClass('two-image');
        } else if (galleryLength == 3) {
            $(item).addClass('three-image');
        } else if (galleryLength >= 4) {
            $(item).addClass('more');
        }
    });
}

window.onload = function() {
    progressiveImageLoader();
};

function progressiveImageLoader() {
    var placeholder = $("figure.figure-progressive");

    placeholder.each(function() {
        var small = this.querySelector('.img-small');

        //load small image and show it
        var img = new Image();
        img.src = small.src;
        img.onload = function () {
            small.classList.add('loaded');
        };

        //load large image
        var imgLarge = new Image();
        imgLarge.src = this.dataset.large;
        imgLarge.srcset = this.dataset.srcset;
        imgLarge.onload = function () {
            imgLarge.classList.add('loaded');
        };
        this.appendChild(imgLarge);
    });
}

function handleFooter() {
    $(window).resize(function() {
        var contentHeight = Math.round($("body").innerHeight());
        var windowWidth= $(window).innerWidth();
        var windowHeight = window.innerHeight;

        if((windowWidth >= 768) && ( contentHeight <= windowHeight)){
            $("footer").addClass("sticky");
        } else {
            $("footer").removeClass("sticky");
        }
    });
}

function handleTable() {
    $('table').addClass('table table-striped');
}

function randomImageSidebar() {
    var bgArray = ['illustratie-1.png', 'illustratie-2.png', 'illustratie-3.png', 'illustratie-4.png'];
    var bg = bgArray[Math.floor(Math.random() * bgArray.length)];

    // If you have defined a path for the images
    var path = location.protocol+'//'+ location.host + '//assets/images/';

    // then you can put it right before the variable 'bg'
    $('.bg-image').css("background-image", "url(" + path + bg + ")");
}

function convertToTap() {
    $(".tabs").each(function (index, element) {

        var tabsContainer = $(element).find(".tab-container");
        var tabsComponent = tabsContainer.find(".tab-component");
        var accordionElement = $('<div class="accordion" id="accordionComponent"></div>');

        $(accordionElement).appendTo(tabsContainer);

        var $newAccordionItemTitle = new Array();
        var $newAccordionItemText = new Array();
        //
        tabsComponent.find(".nav-tabs .nav-item").each(function (index, element) {
            $newAccordionItemTitle.push(element.text);
            $(accordionElement).append('<div class="card"><div class="card-header" id="heading'+ index +'" data-toggle="collapse" data-target="#collapse'+ index +'" aria-controls="collapse'+ index +'">' + $newAccordionItemTitle[index] +'<span class="arrow"><img src="/assets/images/arrow-calendar.png"></span></div></div>');

        });

        tabsComponent.find(".tab-content .tab-pane").each(function (index, element) {
            $newAccordionItemText.push($(this).text());
            accordionElement.find('.card:nth-child(' + (index + 1) +')').append('<div class="collapse" id="collapse'+ index +'" data-parent="#accordionComponent"><div class="card-body">'+ $newAccordionItemText[index] +'</div></div>');
            accordionElement.find('.card:first-child .card-header').attr("aria-expanded","true");
            accordionElement.find('.card:first-child .collapse').addClass("show");
        });

        $(window).resize(function() {
            var screenSize = $( window ).outerWidth();
            if (screenSize < 768) {
                $(accordionElement).appendTo(tabsContainer);
                $(tabsComponent).detach();
            }
            else {
                $(tabsContainer).find(accordionElement).detach();
                $(tabsComponent).appendTo(tabsContainer);
            }
        });

    });
}

function handleSelect() {
    $('.md-select').each(function (index, element) {
        $(element).click('click', function (e) {
            e.preventDefault();
            $(element).toggleClass('active')
        });
        $(element).find('ul li').on('click', function () {
            //default list
            if($(this).find('input').length === 0) {
                $(element).find('ul li').attr('aria-selected', false);
                $(this).attr('aria-selected', true);

                //multiselect list
            } else {
                if($(this).attr('aria-selected') === 'false'){
                    $(this).attr('aria-selected', true);
                } else {
                    $(this).attr('aria-selected', false);
                }
            }

            $(element).find('ul li').not($(this)).removeClass('active');
            $(this).removeClass('active');

            var defaultText = $(element).find('button').data('title');

            if($(element).find('li[aria-selected="true"]').length > 0) {
                $(element).find('label button').html(defaultText + ' <small>(' + $(element).find('li[aria-selected="true"]').length + ' geselecteerd)</small>');
            } else {
                $(element).find('label button').text(defaultText)
            }
        })
    });
}